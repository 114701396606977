import { Checkbox, Grid } from '@mui/material';
import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import PurchaseAttributeCard from './PurchaseAttributeCard';
import { ProductDataType } from '../../../Constants/Procurement/PurchaseRequisition/types';
import { productDescription } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import CustomImageComponent from '../../../Shared/CustomInputs/CustomImageComponent';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';

export interface ProductCardProps {
  click: () => void;
  selected: boolean;
  checkbox?: boolean;
  product: ProductDataType;
}

export const ProductCard = ({
  click,
  selected,
  product,
  checkbox,
}: ProductCardProps) => {
  const attributes = product.attributesMap.CLASSIFICATION.concat(
    product.attributesMap.SPECIFICATION,
    product.attributesMap.GENERALIZATION
  );
  return (
    <div
      className="border border-cgray-4 rounded-md px-3 py-4 hover:shadow-productBox cursor-pointer transition-all duration-100 group "
      onClick={click}
    >
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item xs={2.5}>
          <div className="flex items-center gap-x-4">
            {checkbox && <CustomCheckBox name="checkbox" checked={selected} />}
            <div className="w-max min-w-14">
              <CustomImageComponent
                imageUrl={product?.productCategoryImageURL}
                className=" w-14 h-12 rounded object-cover max-w-full"
              />
            </div>
            <ProductAttributeHeading
              title={'UPC Code'}
              value={product.upcCode}
              className={'text-blue font-medium text-nowrap'}
            />
          </div>
        </Grid>
        <Grid item xs={4.2}>
          <ProductAttributeHeading
            title={'UPC Description'}
            value={productDescription(product)}
            className={'text-sm mt-0.5'}
          />
        </Grid>
        <Grid item xs={5.3}>
          <PurchaseAttributeCard attributes={attributes} />
        </Grid>
      </Grid>
    </div>
  );
};
